import React from "react";
import { Form } from "react-router-dom";

const Contact = () => {
  React.useEffect(() => {
    document.title = "Create new account";
  });

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [errorEmail, setErrorEmail] = React.useState(false);
  const [errorPassword, setErrorPassword] = React.useState(false);

  const handlerSubmit = (e) => {
    e.preventDefault();

    if (email === "") setErrorEmail(true);
    if (password === "") setErrorPassword(true);

    if (email !== "") {
      console.log(email);
    }

    if (password !== "") {
      console.log(password);
    }
  };

  const handlerInputEmail = (param) => {
    setEmail(param);
    setErrorEmail(false);
  };

  const handlerInputPassword = (param) => {
    setPassword(param);
    setErrorPassword(false);
  };

  return (
    <div className="mt-3">
        <h1 className="contact-sd">Contact</h1>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body p-4">
              <form onSubmit={handlerSubmit}>
                <div className="mb-3">
                  <label
                    htmlFor="email"
                    className="form-label text-start d-block mb-2 fw-bold"
                  >
                    Email<span className="text-danger">*</span>
                  </label>
                  <div className="mb-3">
                    <input
                      type="email"
                      placeholder="Email"
                      className={
                        errorEmail === false
                          ? "form-control"
                          : "form-control is-invalid"
                      }
                      onChange={(nilai) =>
                        handlerInputEmail(nilai.target.value)
                      }
                    />
                    <div className="text-start text-danger small">
                      {errorEmail && "The Field  is Required!"}
                    </div>
                  </div>

                  <label
                    htmlFor="email"
                    className="form-label text-start d-block mb-2 fw-bold"
                  >
                    Password<span className="text-danger">*</span>
                  </label>
                  <div className="mb-3">
                    <input
                      type="password"
                      placeholder="Put Your Password"
                      className={
                        errorPassword === false
                          ? "form-control"
                          : "form-control is-invalid"
                      }
                      onChange={(nilai) =>
                        handlerInputPassword(nilai.target.value)
                      }
                    />
                    <div className="text-danger text-start small">
                      {errorPassword && "The  Field is Required!"}
                    </div>
                  </div>
                </div>
                <button className="btn btn-primary" onClick={handlerSubmit}>
                  Sign Up
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
