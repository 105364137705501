import { Link, useMatch, useResolvedPath } from "react-router-dom";
import '../App.css'

import React from "react";
const Navbar = () => {

    // const location = useLocation()

    // console.log(`Lokasi Saat Ini Berada Di :${location}`)

    return (
      <nav className="nav">
        <Link to="/" className="site-title">
          Home
        </Link>
        <ul>
       
          <CustomLink to="/about">About</CustomLink>
          <CustomLink to="/contact">Contact</CustomLink>
        </ul>
      </nav>
    );
}


function CustomLink({ to, children, ...props }) {
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch({ path: resolvedPath.pathname, end: true })

    return(
        <li className={ isActive ? 'active' : ""}>
            <Link to={to} {...props}>
                {children}
            </Link>
        </li>
    )
    
}

export default Navbar