import React from "react";
const About = () => {
  return (
   <div class="center">
            <h1 class="avatar tentang-saya">About</h1>
            <p class="tentang-des">
               Ini adalah ujian React js website ini menggunakan framework bootstrap dan react js
            </p>
        </div>
  );
};

export default About;
