import React from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

const Home = () => {
  return (
    <div class="center">
      <div class="avatar">
        <h1>React JS</h1>
      </div>
      <p class="programmer">ujian.</p>

      <Link to="/about"   class="learn">
        About.
      </Link>
    </div>
  );
};

export default Home;
